import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"map",staticClass:"map"},[_c(VSnackbar,{attrs:{"bottom":"","right":"","color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c(VBtn,{attrs:{"color":"white","text":"","icon":""},on:{"click":function($event){_vm.snackbar.value = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true}]),model:{value:(_vm.snackbar.value),callback:function ($$v) {_vm.$set(_vm.snackbar, "value", $$v)},expression:"snackbar.value"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('div',{staticClass:"orientation d-flex justify-center"},[_c(VBtn,{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.orientation),expression:"orientation",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"clipboard",rawName:"v-clipboard:error",value:(_vm.onError),expression:"onError",arg:"error"},{name:"show",rawName:"v-show",value:(_vm.orientation),expression:"orientation"}],staticClass:"mt-2",attrs:{"outlined":!_vm.$store.state.orientation,"color":_vm.$store.state.orientation ? 'primary' : 'orange'}},[_vm._v(" "+_vm._s(_vm.orientation)+" ")])],1),_c('div',{staticClass:"mapboxgl-control-container"},[_c('div',{staticClass:"mapboxgl-ctrl-top-left"},[_c('div',[_c('div',{staticClass:"mapboxgl-ctrl mapboxgl-ctrl-group"},[_c(VMenu,{attrs:{"offset-x":"","close-on-content-click":false,"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({staticClass:"mapboxgl-ctrl-fullscreen"},on),[_c(VIcon,{staticStyle:{"margin-top":"-3px"},attrs:{"size":"20","color":"black"}},[_vm._v("mdi-layers")])],1)]}}]),model:{value:(_vm.dialog.layer),callback:function ($$v) {_vm.$set(_vm.dialog, "layer", $$v)},expression:"dialog.layer"}},[_c('map-setting',{attrs:{"show":_vm.mapSettingFields,"linkedGroups":_vm.linkedGroupsLabels},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Layers "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog.layer = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)]},proxy:true}])})],1),_c('select-map-layer',{model:{value:(_vm.mapStyle),callback:function ($$v) {_vm.mapStyle=$$v},expression:"mapStyle"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }